import { NEWSCONSTANT } from "../../constants/News";

export const talkApis = {
  GET_TALK: NEWSCONSTANT.newaapiprefix + "/get-paginated-talk",
  GET_TALK_ID: NEWSCONSTANT.newaapiprefix + "/get-single-talk",
  CREATE_TALK: NEWSCONSTANT.newaapiprefix + "/create-talk",
  DELETE_TALK: NEWSCONSTANT.newaapiprefix + "/delete-talk",
  UPDATE_STATUS_TALK: NEWSCONSTANT.newaapiprefix + "/deactivate-talk",
  UPDATE_TALK: NEWSCONSTANT.newaapiprefix + "/update-talk",
};
