import React, { memo, useMemo, useState } from "react";
import { CONSTANTS } from "../../components/Dynamic-Table/types/constants";
import { Button, Flex, Title } from "@mantine/core";
import FButton from "../../components/Button/FButton";
import { useNavigate } from "react-router-dom";
import DefaultTable from "../../components/Dynamic-Table/table/DefaultTable";
import { useGetPaginatedBlogs } from "../../hooks/blogs/queries/useGetPaginatedBlogs";
import { blogColumns } from "../../constants/Blogs/BlogHeading";
import { useGetPaginatedCampus } from "../../hooks/campus/queries/useGetPaginatedCampus";
import { campusColumns } from "../../constants/Campus/CampusHeading";

const CampusTab = () => {
  const [pagedData, setPagedData] = useState({ total: 0 });
  const [activePage, setActivePage] = useState(1);
  const { data, isLoading } = useGetPaginatedCampus({
    activePage,
    itemsPerPage: CONSTANTS.PAGE_LIMIT,
  });
  const navigate = useNavigate();
  const newData = useMemo(() => {
    // console.log("data blogs", data);
    if (!isLoading && data?.status === "success") {
      data?.pageData && setPagedData(data.pageData);
      return data.data;
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <div>
      <Flex
        justify={"space-between"}
        pb={20}
        pt={10}
        pr={"xl"}
        align={"center"}
      >
        <Title order={5}>All Campus Posts</Title>
        <FButton
          label="Add New"
          color="blue"
          textAlign={"end"}
          width="100"
          onClick={() => navigate("/campus/add-campus")}
        />
      </Flex>

      <DefaultTable
        columns={campusColumns as TTableColumns<unknown>[]}
        data={newData}
        isLoading={isLoading}
        paginationProps={{
          setPage: setActivePage,
          totalPages: pagedData.total,
        }}
      />
    </div>
  );
};

export default memo(CampusTab);
