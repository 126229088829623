import { NEWSCONSTANT } from "../../constants/News";

export const courseApis = {
  GET_COURSE: NEWSCONSTANT.newaapiprefix + "/get-paginated-course",
  GET_COURSE_ID: NEWSCONSTANT.newaapiprefix + "/get-single-course",
  CREATE_COURSE: NEWSCONSTANT.newaapiprefix + "/create-course",
  DELETE_COURSE: NEWSCONSTANT.newaapiprefix + "/delete-course",
  UPDATE_STATUS_COURSE: NEWSCONSTANT.newaapiprefix + "/deactivate-course",
  UPDATE_COURSE: NEWSCONSTANT.newaapiprefix + "/update-course",
};
