import { IFormData } from "../../Types";
import BlogActionBar from "../../components/ActionBar/BlogActionBar";
import CourseActionBar from "../../components/ActionBar/CourseActionBar";

export const courseColumns: TTableColumns<IFormData>[] = [
  {
    key: "title",
    label: "Title",
    minWidth: "20%",
    renderCell: (value) => value.cardData.title,
  },
  {
    key: "description",
    label: "Description",
    minWidth: "30%",
    renderCell: (value) => value.cardData.description,
  },
  {
    key: "cardImg",
    label: "Card Image",
    minWidth: "20%",
    renderCell: (value: any) => (
      <img src={value.cardData.cardImg} alt="Card" style={{ maxWidth: 100 }} />
    ),
  },
  {
    key: "date",
    label: "Date",
    minWidth: "15%",
    renderCell: (value) => new Date(value.date).toLocaleDateString(),
  },
  {
    key: "actions",
    label: "Actions",
    minWidth: "15%",
    renderCell: (value) => <CourseActionBar value={value} />,
  },
];
