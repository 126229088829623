import { blogApis } from "./blogs.api";
import { eventsApi } from "./events.api";
import { newsApis } from "./news.api";
import { researchApis } from "./research.api";
import { storiesApi } from "./stories.api";
import { newsletterApi } from "./newsletter.api";
import { contactFormApi } from "./contactForm.api";
import { courseApis } from "./course.api";
import { campusApis } from "./campus.api";
import { talkApis } from "./talk.api";

export const apiUrls = {
  ...newsApis,
  ...blogApis,
  ...storiesApi,
  ...eventsApi,
  ...researchApis,
  ...newsletterApi,
  ...contactFormApi,
  ...campusApis,
  ...courseApis,
  ...talkApis,
};
