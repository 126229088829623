import { useMutation, useQueryClient } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface Idata {
  _id: string;
  isActive: boolean;
}

const updateNews = async (data: Idata) => {
  const res: TServerResponse = await request({
    url: apiUrls.UPDATE_STATUS_NEWS + `/${data._id}`,
    method: "POST",
    data: { data },
  });

  return res;
};

export const useUpdateStatusNewsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateNews,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["news"] });
      return true;
    },
  });
};
