import React from "react";
import { useCreateBlogsMutation } from "../../../hooks/blogs/mutations/useCreateBlogs";
import { blogValidation } from "../../../validation/blogs.validation";
import { useGetBlogById } from "../../../hooks/blogs/queries/useGetBlogById";
import { useUpdateBlogMutation } from "../../../hooks/blogs/mutations/useUpdateBlog";
import GenericForm from "../../../components/ContentForm/GenericForm";
import { useCreateCourseMutation } from "../../../hooks/course/mutations/useCreateCourse";
import { useGetCourseById } from "../../../hooks/course/queries/useGetCourseById";
import { useUpdateCourseMutation } from "../../../hooks/course/mutations/useUpdateCourse";

const CourseAddForm: React.FC = () => {
  return (
    <>
      <GenericForm
        entityName="course"
        displayName="Course"
        useCreateMutation={useCreateCourseMutation}
        useFetchById={useGetCourseById}
        useUpdateMutation={useUpdateCourseMutation}
        validationSchema={blogValidation}
      />
    </>
  );
};

export default CourseAddForm;
