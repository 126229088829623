import { IFormData } from "../../Types";
import { memo } from "react";
import { usedelteBlogMutation } from "../../hooks/blogs/mutations/useDeleteBlog";
import { showNotification } from "@mantine/notifications";
import { useNavigate } from "react-router-dom";
import CommonActionBar from "./CommonActionBar/CommonActionBar";
import { useUpdateBlogMutation } from "../../hooks/blogs/mutations/useUpdateBlog";
import { useUpdateStatusBlogMutation } from "../../hooks/blogs/mutations/useUpdateStatus";
import { ActionIcon, Flex, Tooltip } from "@mantine/core";
import { IconEdit, IconEye, IconEyeOff, IconTrash } from "@tabler/icons-react";
import { confirmationAlert } from "../../container/modal/confirmationAlert";
import { usedelteTalkMutation } from "../../hooks/talks/mutations/useDeleteTalk";
import { useUpdateStatusTalkMutation } from "../../hooks/talks/mutations/useUpdateStatus";

const TalkActionBar = ({ value }: { value: IFormData }) => {
  const { mutateAsync: deleteBlog, isPending } = usedelteTalkMutation();
  const { mutateAsync: updateStatus } = useUpdateStatusTalkMutation();
  const navigate = useNavigate();
  const handleEdit = (id: string) => {
    navigate(`/talk/edit/${id}`);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteBlog({ _id: id });
      showNotification({
        title: "Talk  post Deleted",
        message: "Done",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Can't Delete Talk post",
        message: "Server Error",
        color: "red",
      });
    }
  };

  const handleToggleActive = async (id: string, isActive: boolean) => {
    try {
      // console.log("isActive Value", isActive);
      await updateStatus({ _id: id, isActive: isActive });
      showNotification({
        title: "Status Updated",
        message: "Talk post Updated Successfully",
        color: "green",
      });
    } catch (error) {
      showNotification({
        title: "Can't Update Status",
        message: "Internal Server Error",
        color: "red",
      });
    }
  };
  return (
    <div>
      <Flex gap="xs" justify="center">
        <Tooltip label="Edit">
          <ActionIcon onClick={() => handleEdit(value._id ?? "")}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon
            color="red"
            onClick={() =>
              confirmationAlert({
                title: "Delete Blog",
                msg: "Are you sure want to delete this talk post?",
                labels: { cancel: "cancel", confirm: "Delete" },
                onConfirm() {
                  handleDelete(value._id ?? "");
                },
              })
            }
            type="button"
            px={2}
            py={3}
            loading={isPending}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
        <Tooltip label={value.isActive ? "Deactivate" : "Activate"}>
          <ActionIcon
            color="gray"
            onClick={() => handleToggleActive(value._id ?? "", value.isActive)}
          >
            {value.isActive ? <IconEye /> : <IconEyeOff />}
          </ActionIcon>
        </Tooltip>
      </Flex>
    </div>
  );
};

export default memo(TalkActionBar);
