import { useMutation } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { IFormData, TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

const updateBlog = async ({ data, id }: { data: IFormData; id: string }) => {
  const res: TServerResponse = await request({
    url: apiUrls.UPDATE_CAMPUS + `/${id}`,
    method: "POST",
    data: data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res;
};

export const useUpdateCampusMutation = () => {
  return useMutation({
    mutationFn: updateBlog,
  });
};
