import { NEWSCONSTANT } from "../../constants/News";

export const newsApis = {
  GET_NEWS: NEWSCONSTANT.newaapiprefix + "/get-paginated-news",
  GET_NEWS_ID: NEWSCONSTANT.newaapiprefix + "/get-single-news",
  CREATE_NEWS: NEWSCONSTANT.newaapiprefix + "/create-news",
  DELETE_NEWS: NEWSCONSTANT.newaapiprefix + "/delete-news",
  UPDATE_STATUS_NEWS: NEWSCONSTANT.newaapiprefix + "/deactivate-news",
  UPDATE_NEWS: NEWSCONSTANT.newaapiprefix + "/update-news",
};
