import { NEWSCONSTANT } from "../../constants/News";

export const campusApis = {
  GET_CAMPUS: NEWSCONSTANT.newaapiprefix + "/get-paginated-campus",
  GET_CAMPUS_ID: NEWSCONSTANT.newaapiprefix + "/get-single-campus",
  CREATE_CAMPUS: NEWSCONSTANT.newaapiprefix + "/create-campus",
  DELETE_CAMPUS: NEWSCONSTANT.newaapiprefix + "/delete-campus",
  UPDATE_STATUS_CAMPUS: NEWSCONSTANT.newaapiprefix + "/deactivate-campus",
  UPDATE_CAMPUS: NEWSCONSTANT.newaapiprefix + "/update-campus",
};
