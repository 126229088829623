import React from "react";
import { useCreateBlogsMutation } from "../../../hooks/blogs/mutations/useCreateBlogs";
import { blogValidation } from "../../../validation/blogs.validation";
import { useGetBlogById } from "../../../hooks/blogs/queries/useGetBlogById";
import { useUpdateBlogMutation } from "../../../hooks/blogs/mutations/useUpdateBlog";
import GenericForm from "../../../components/ContentForm/GenericForm";
import { useCreateNewsMutation } from "../../../hooks/news/mutations/useSubmitNews";
import { useGetNewsById } from "../../../hooks/news/queries/useGetNewsById";
import { useUpdateNewsMutation } from "../../../hooks/news/mutations/useUpdateNews";

const NewsAddForm: React.FC = () => {
  return (
    <>
      <GenericForm
        entityName="news"
        displayName="News"
        useCreateMutation={useCreateNewsMutation}
        useFetchById={useGetNewsById}
        useUpdateMutation={useUpdateNewsMutation}
        validationSchema={blogValidation}
      />
    </>
  );
};

export default NewsAddForm;
