import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { TServerResponse } from "../../../Types";
import { apiUrls } from "../../api-urls";

interface IData {
  _id: string;
}

export const getBlog = async (data: IData) => {
  const res: TServerResponse = await request({
    url: apiUrls.GET_COURSE_ID + `/${data._id}`,
    method: "GET",
  });
  return res;
};

export const useGetCourseById = (data: IData, isEditing: boolean) => {
  return useQuery({
    queryKey: ["course", data._id],
    queryFn: () => getBlog(data),
    enabled: isEditing,
  });
};
