import React from "react";
import { useCreateBlogsMutation } from "../../../hooks/blogs/mutations/useCreateBlogs";
import { blogValidation } from "../../../validation/blogs.validation";
import { useGetBlogById } from "../../../hooks/blogs/queries/useGetBlogById";
import { useUpdateBlogMutation } from "../../../hooks/blogs/mutations/useUpdateBlog";
import GenericForm from "../../../components/ContentForm/GenericForm";
import { useCreateCampusMutation } from "../../../hooks/campus/mutations/useCreateCampus";
import { useGetCampusById } from "../../../hooks/campus/queries/useGetCampusById";
import { useUpdateCampusMutation } from "../../../hooks/campus/mutations/useUpdateCampus";

const CampusAddForm: React.FC = () => {
  return (
    <>
      <GenericForm
        entityName="campus"
        displayName="Campus"
        useCreateMutation={useCreateCampusMutation}
        useFetchById={useGetCampusById}
        useUpdateMutation={useUpdateCampusMutation}
        validationSchema={blogValidation}
      />
    </>
  );
};

export default CampusAddForm;
