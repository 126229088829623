import React from "react";
import { useCreateBlogsMutation } from "../../../hooks/blogs/mutations/useCreateBlogs";
import { blogValidation } from "../../../validation/blogs.validation";
import { useGetBlogById } from "../../../hooks/blogs/queries/useGetBlogById";
import { useUpdateBlogMutation } from "../../../hooks/blogs/mutations/useUpdateBlog";
import GenericForm from "../../../components/ContentForm/GenericForm";
import { useCreateTalkMutation } from "../../../hooks/talks/mutations/useCreateTalk";
import { useGetTalkById } from "../../../hooks/talks/queries/useGetTalkById";
import { useUpdateTalkMutation } from "../../../hooks/talks/mutations/useUpdateTalk";

const TalkAddForm: React.FC = () => {
  return (
    <>
      <GenericForm
        entityName="talk"
        displayName="Talk"
        useCreateMutation={useCreateTalkMutation}
        useFetchById={useGetTalkById}
        useUpdateMutation={useUpdateTalkMutation}
        validationSchema={blogValidation}
      />
    </>
  );
};

export default TalkAddForm;
